<template>
  <div class="w-full">
    <div class="pr-2 w-full flex items-end justify-end">
      <div class="flex justify-end">
        <div
          v-if="toggleHelp"
          class="w-full md:w-7/12 p-4 mr-2 border border-primary rounded-md bg-lightBlue"
        >
          <p class="text-left">
            <b>Public sharing</b> means that non-users can view the information
            you choose to share. You should choose this for sharing with vets
            etc. <br /><br />
            <b>Private sharing</b> requires the viewer to either have or create
            an account. You should choose this option to keep all your data
            private.
          </p>
        </div>
        <QuestionmarkIconVue
          iconColor="#F8961F"
          :height="50"
          :width="50"
          class="cursor-pointer"
          @click.native="toggleHelp = !toggleHelp"
        />
      </div>
    </div>
    <div
      class="mt-5 md:px-10 px-5 w-full flex flex-col lg:flex-row justify-between"
    >
      <div class="lg:w-4/12 w-full">
        <h1
          class="w-full md:w-9/12 text-left text-xl text-primary font-proximaMedium"
        >
          Sharing Rules
        </h1>
        <div class="lg:w-max w-full">
          <FormError :errors="errorForm" />
          <PetRecordEach
            v-for="(setting, i) in settings"
            :key="i"
            :setting="setting"
            @settingVal="settingVal"
          />
        </div>
      </div>
      <div class="lg:mt-0 mt-10 lg:w-4/12 w-full">
        <div class="flex flex-col justify-start items-start">
          <h1
            class="w-full md:w-9/12 text-left text-xl text-primary font-proximaMedium"
          >
            Note (optional)
          </h1>
          <textarea
            v-model="note"
            placeholder="You can add notes for pet sitters, friends etc. "
            class="mt-2 p-2 w-full lg:w-9/12 h-44 border-2 border-primary rounded-md"
          ></textarea>
        </div>
        <div class="mt-10 flex flex-col justify-start items-start">
          <h3 class="mb-4 text-left text-xl text-primary font-proximaMedium">
            Validity (optional)
          </h3>
          <datepicker
            v-model="expiration_date"
            @selected="getExpDate"
            :inline="true"
          ></datepicker>
        </div>
      </div>
      <div class="lg:mt-0 mt-10 lg:w-4/12 w-full">
        <div>
          <h3 class="mb-2 text-left text-primary text-lg font-bold">Access</h3>
          <InputRadioEachReverseVue
            v-if="!publicLink"
            class="pb-1"
            title="Generate Public Link"
            backgroundCl="bg-white"
            @inputVal="inputValAccess"
            :id="1"
          />
          <IconButton
            v-else
            class="font-proximaLight mb-4"
            :title="copyBtn"
            background="bg-orange"
            paddingX="px-8"
            width="w-full"
            component="LinkIcon"
            iconColor="#fff"
            @click.native="copyLink"
          />
          <InputRadioEachReverseVue
            v-if="!qrCode"
            class="py-1"
            title="Generate QR Code"
            backgroundCl="bg-white"
            @inputVal="inputValAccess"
            :id="2"
          />
          <IconButton
            v-else
            class="font-proximaLight mb-4"
            :title="$t('share_pet_record_generate_qr_code')"
            background="bg-orange"
            paddingX="px-8"
            width="w-full"
            component="BarcodeIcon"
            iconColor="#fff"
            @click.native="openQrCode"
          />
          <InputRadioEachReverseVue
            class="py-1"
            title="Public Link via Email"
            backgroundCl="bg-white"
            @inputVal="inputValAccess"
            :id="3"
          />
          <input
            v-if="accessId == 3"
            v-model="emailPhone"
            type="email"
            placeholder="johndoe@email.com"
            class="w-full p-2 mb-4 border border-primary rounded-md"
          />
          <InputRadioEachReverseVue
            class="py-1"
            title="Private Link via Email"
            backgroundCl="bg-white"
            @inputVal="inputValAccess"
            :id="4"
          />
          <input
            v-if="accessId == 4"
            type="email"
            placeholder="johndoe@email.com"
            class="w-full p-2 mb-4 border border-primary rounded-md"
          />
          <InputRadioEachReverseVue
            class="pt-1"
            title="Private Contact Share"
            backgroundCl="bg-white"
            @inputVal="inputValAccess"
            :id="5"
          />
          <div
            v-if="accessId == 5"
            class="h-52 w-full overflow-scroll overflow-x-hidden"
          >
            <div
              class="my-2 flex items-center justify-between"
              v-for="(contact, i) in contacts"
              :key="i"
            >
              <div class="flex items-center">
                <img
                  class="h-6"
                  src="@/assets/images/profilepic-icon.png"
                  alt="profilepic-icon"
                />
                <span class="ml-2">{{ contact.name }}</span>
              </div>
              <RadioOnoffBorder
                :val="contact"
                :checked="contact.checked"
                :checkAll="checkAll"
                @inputVal="inputVal"
              />
            </div>
          </div>
        </div>
        <div class="w-full px-10 flex justify-center">
          <div class="lg:mt-0 md:mb-10 mt-10 w-full flex">
            <BorderedButton
              slot="leftBtn"
              class="mr-1"
              :title="$t('global_cancel')"
              borderColor="border-mediumGrey"
              textCl="text-darkGrey"
              paddingX="px-8"
              width="w-full"
              @click.native="closeModals"
            />
            <SubmitButton
              slot="rightBtn"
              class="ml-1"
              title="Save"
              background="bg-primary"
              paddingX="px-8"
              width="w-full"
              @click.native="submitContactModal"
            />
          </div>
        </div>
      </div>
      <BarcodeModals :codeQR="codeQR" />
      <EmailPhoneModals
        :petID="this.pet.petID"
        :shareable_objects="this.settingAdd"
        :notes="this.note"
        :expiration_date="this.expiration_date"
      />
    </div>
  </div>
</template>

<script>
import PetRecordEach from "../PetRecordEach.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import IconButton from "../../buttons/IconButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import Datepicker from "vuejs-datepicker";
import BarcodeModals from "../BarcodeModals.vue";
import EmailPhoneModals from "../EmailPhoneModals.vue";
import AddNoteModals from "../AddNoteModals.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import InputRadioEachReverseVue from "../../forms/InputRadioEachReverse.vue";

import { mapActions, mapGetters } from "vuex";
import FormError from "../../warning/FormError.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import moment from "moment";
import QuestionmarkIconVue from "../../icons/QuestionmarkIcon.vue";
import { checkUrlQRExists } from "../../../services/sharingService";

export default {
  props: {
    show: Boolean,
    settingInitAdd: Array,
  },
  components: {
    PetRecordEach,
    SubmitButton,
    BorderedButton,
    IconButton,
    Datepicker,
    BarcodeModals,
    EmailPhoneModals,
    ModalsConfirm,
    AddNoteModals,
    FormError,
    RadioOnoffBorder,
    InputRadioEachReverseVue,
    QuestionmarkIconVue,
  },

  data() {
    return {
      pet: {},
      bulkShareModals: false,
      preference: [],
      settingAdd: [],
      settingInitAdd_: this.preference,
      dob: null,
      expiration_date: null,
      note: "",
      checkAll: false,
      contactsList: [],
      valideButton1: false,
      valideButton2: true,
      showTooltipLink: false,
      publicLink: false,
      qrCode: false,
      emailInput: false,
      accessId: "",
      emailPhone: "",
      copyBtn: "Public Link",
      toggleHelp: false,
    };
  },
  computed: {
    ...mapGetters({
      codeQR: "sharing/getCodeQR",
      link: "sharing/getLink",

      contacts: "contact/getContacts",
    }),

    errorForm() {
      return this.$store.state.errorForm;
    },
    settings() {
      return [
        {
          id: "1",
          name: "Photo Gallery",
          checked: this.preference.includes(Number("1")) ? true : false,
        },
        {
          id: "2",
          name: this.$t("pet_dropdown_menu_care_routine"),
          checked: this.preference.includes(Number("2")) ? true : false,
        },
        /*  {
          id: "3",
          name: this.$t("pet_dropdown_menu_treatment_plan"),
          checked: this.preference.includes(Number("3")) ? true : false,
        }, */
        {
          id: "4",
          name: this.$t("pet_dropdown_menu_symptom_tracker"),
          checked: this.preference.includes(Number("4")) ? true : false,
        },
        {
          id: "5",
          name: this.$t("pet_record_breeding_status"),
          checked: this.preference.includes(Number("5")) ? true : false,
        },
        /* {
          id: "6",
          name: "Blood Donor Status",
          checked: this.preference.includes(Number("6")) ? true : false,
        }, */
        {
          id: "7",
          name: "Favourite Services",
          checked: this.preference.includes(Number("7")) ? true : false,
        },
        {
          id: "8",
          name: "Medical History",
          checked: this.preference.includes(Number("8")) ? true : false,
        },
      ];
    },
  },
  async created() {
    console.log("settings : ", this.settings);
    this.$store.commit("errorForm", "");
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    this.preference = localStorage.getItem("sharingRulesPreferences");
    this.settingAdd = JSON.parse(this.preference);
  },

  async mounted() {
    console.log("settings : ", this.settings);
    this.$store.commit("errorForm", "");
    if (localStorage.getItem("sharingRulesPreferences")) {
      this.preference = localStorage.getItem("sharingRulesPreferences");
      console.log("-------mounted---", this.preference);

      this.settingAdd = JSON.parse(this.preference);
      console.log("-------mounted---");
      console.log("8888 mt", this.preference);
      //this.settingAdd = [];
    }
    this.expiration_date = null;

    const userEmail = localStorage.getItem("email");
    if (userEmail) {
      this.getContacts(userEmail);
    }

    const data = {
      newJoinerEmailAddress: this.emailPhone,
      granted_by: localStorage.getItem("email"),
      petID: this.pet.petID,
      shareable_objects: JSON.parse(this.preference), // uniquesettingAdd,
    };

    const response = await checkUrlQRExists(data);
    if (response.data.link !== "") {
      this.accessId = 1;
      this.publicLink = true;
      this.$store.commit("sharing/setLink", response.data.link);
    }
    if (response.data.qrcode !== "") {
      this.accessId = 2;
      this.qrCode = true;
      this.$store.commit("sharing/setCodeQR", response.data.qrcode);
    }
    console.log("response", response);
  },
  methods: {
    ...mapActions({
      shareByEmail: "sharing/shareByEmail",
      addSharingRule: "sharing/addSharingRule",
      userSharingPreferences: "sharing/userSharingPreferences",
      sharingEmailPhoneModals: "sharing/sharingEmailPhoneModals",
      getContacts: "contact/getContacts",
    }),
    inputValAccess(i) {
      // console.log(i);
      this.accessId = i;
    },
    settingVal(val, checked) {
      if (checked) {
        this.settingAdd = [...this.settingAdd, Number(val)];
        console.log("add : ", this.settingAdd);
      } else {
        this.settingAdd = this.settingAdd.filter((p) => p !== Number(val));
        console.log("remove : ", val, this.settingAdd);
      }

      this.valideButton1 = this.settingAdd.length > 0 ? true : false;

      console.log(
        "valideButton1",
        this.valideButton1,
        this.preference.length,
        this.settingAdd
      );
    },
    closeModals() {
      this.$store.commit("errorForm", "");
      this.bulkShareModals = false;
    },
    closeModalsharing() {
      this.$store.commit("errorForm", "");
      this.settingAdd = [];
      this.expiration_date = null;
      this.$emit("close");
    },

    async submitContactModal() {
      /* get existing sharing preference */
      if (localStorage.getItem("sharingRulesPreferences")) {
        var p = localStorage.getItem("sharingRulesPreferences");
        var pp = JSON.parse(p);
        this.settingAdd = [...this.settingAdd, ...pp];
      }

      if (this.settingAdd.length == 0) {
        this.$store.commit("errorForm", "Sharing permissions are required.");
      } else {
        //----------------------------------data validated -----------------
        let uniquesettingAdd = [...new Set(this.settingAdd)];

        if (this.accessId == 3 && this.emailPhone == "") {
          this.$store.commit(
            "errorForm",
            "Email or Phone number " + this.$t("global_is_required")
          );
          return;
        }
        if (this.accessId == 5 && this.contactsList.length == 0) {
          this.$store.commit(
            "errorForm",
            "Please choose at least one contact."
          );
          return;
        }

        if (this.accessId == 5) {
          const vals = this.contactsList.filter((p) => p.checked == true);
          vals.map((v) => {
            console.log("tttttttttt", v, v.userEmailAddress);

            const data = {
              newJoinerEmailAddress: v.contactEmailAddress,
              granted_by: localStorage.getItem("email"),
              petID: this.pet.petID,
              shareable_objects: uniquesettingAdd,
              notes: this.note,
            };
            this.expiration_date == null
              ? null
              : (data.expiration_date = moment(this.expiration_date).format(
                  "DD/MM/YYYY"
                )),
              this.addSharingRule({
                sharingRule: data,
                choice: this.accessId,
                link: null,
              });
          });
        } else {
          const data = {
            newJoinerEmailAddress: this.emailPhone,
            granted_by: localStorage.getItem("email"),
            petID: this.pet.petID,
            shareable_objects: uniquesettingAdd,
            notes: this.note,
          };

          this.expiration_date == null
            ? null
            : (data.expiration_date = moment(this.expiration_date).format(
                "DD/MM/YYYY"
              )),
            await checkUrlQRExists(data)
              .then((response) => {
                if (this.accessId == 1) {
                  this.addSharingRule({
                    sharingRule: data,
                    choice: this.accessId,
                    link: response.data.link,
                  });
                } else if (this.accessId == 2) {
                  this.addSharingRule({
                    sharingRule: data,
                    choice: this.accessId,
                    link: response.data.qrcode,
                  });
                } else {
                  this.addSharingRule({
                    sharingRule: data,
                    choice: this.accessId,
                    link: null,
                  });
                }
              })
              .catch(async (error) => {
                if (error.response.status === 404) {
                  this.addSharingRule({
                    sharingRule: data,
                    choice: this.accessId,
                    link: null,
                  });
                }
              });

          if (this.accessId == 1) {
            this.publicLink = true;
          }
          if (this.accessId == 2) {
            this.qrCode = true;
          }
        }
      }
    },

    getExpDate(a) {
      console.log(moment(a).format("DD/MM/YYYY"));
      this.expiration_date = moment(a).format("DD/MM/YYYY");
      //this.valideButton2 = this.expiration_date != null ? true : false;
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.link)
        .then(() => {
          // Optional: Provide feedback to the user, like a success message
          this.copyBtn = "Copied !";
        })
        .catch((err) => {
          console.error("Failed to copy the link: ", err);
        });
    },
    openQrCode() {
      this.$store.commit("sharingBarcodeModals", true);
    },

    openEmailModals() {
      if (localStorage.getItem("sharingRulesPreferences")) {
        var p = localStorage.getItem("sharingRulesPreferences");
        var pp = JSON.parse(p);
        this.settingAdd = [...this.settingAdd, ...pp];
      }

      if (this.settingAdd.length == 0) {
        this.$store.commit("errorForm", "Sharing permissions are required.");
      } else {
        /* else if (this.expiration_date == null) {
        this.$store.commit("errorForm", "Date is required.");
      } */
        this.$store.commit("errorForm", "");
        this.sharingEmailPhoneModals(true);
      }
    },
    openAddNoteModals() {
      this.$store.commit("addNoteModals", true);
    },
    getNote(note) {
      this.note = note;
    },

    async submitAddSharingRule() {
      /* FIXME: preference + add settings */

      if (this.settingAdd.length == 0) {
        this.$store.commit("errorForm", "Sharing permissions are required.");
      } else {
        this.$store.commit("errorForm", "");

        let uniquesettingAdd = [...new Set(this.settingAdd)];
        const req = {
          userEmailAddress: localStorage.getItem("email"),
          shareable_objects: uniquesettingAdd,
        };
        console.log(req);
        this.userSharingPreferences(req);
      }
    },
    checkAllFunc(e) {
      // console.log(e.originalTarget.checked);
      this.checkAll = e.originalTarget.checked;
    },
    inputVal(contact, checked) {
      contact.checked = checked;
      console.log(contact);

      const contacts = [...this.contacts];
      const index = contacts.indexOf(contact);
      contacts[index] = { ...contact };
      this.contactsList = contacts;
    },
  },
};
</script>

<style lang="scss">
.vdp-datepicker__calendar {
  border: none !important;
}

.vdp-datepicker__calendar {
  margin: auto !important;
}

.vdp-datepicker__calendar .cell.selected {
  background: #0ac0cb !important;
  color: #fafafa !important;
  border-radius: 100% !important;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border-radius: 100% !important;
  border: 1px solid #0ac0cb !important;
}
</style>
