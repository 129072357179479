<template>
  <div class="flex flex-col justify-center items-center">
    <div class="w-full">
      <HeaderGlobal
        :titlemenu1="this.titlemenu1"
        :to1="'/petoverview/new'"
        :titlemenu2="this.titlemenu2"
        :titlemenu3="this.titlemenu3"
      />
    </div>

    <div
      v-if="pet"
      class="w-full mt-20 text-left font-proximaLight"
      style="height: 50%"
    >
      <div
        class="mb-4 w-full text-primary font-bold flex items-center justify-between"
      >
        <div class="w-4/12 text-center lg:text-left">Sharing Rules</div>
        <div class="w-3/12 text-center lg:text-left">Access</div>
        <div class="w-5/12 text-center lg:text-left lg:pl-8">Validity</div>
      </div>
      <div class="h-96 w-full overflow-y-auto">
        <div v-for="(data, i) in orderedList(this.sharingRules)" :key="i">
          <ul
            class="row py-6 text-sm border-b border-lightGrey flex items-center justify-between"
          >
            <li
              class="w-4/12 md:w-5/12 flex flex-wrap flex-col md:flex-row items-center gap-1 md:gap-4 mr-2"
            >
              <div v-if="data.shareable_objects.length >= 6">
                <p>Full Sharing</p>
              </div>
              <div v-else v-for="(d, j) in data.shareable_objects" :key="j">
                <div
                  class="mt-1 lg:mt-0 lg:ml-0.5 w-max bg-mediumGrey rounded-full p-2 flex items-center justify-center"
                >
                  <component
                    :is="whichIcon(d)"
                    width="34"
                    height="34"
                    iconColor="#fff"
                  />
                </div>
              </div>
            </li>
            <li class="w-3/12 md:w-5/12 flex flex-col md:flex-row items-center">
              <!-- <img class="rounded-full h-6 w-6" src="@/assets/images/pp-1.png" alt="pp-1" />
          <img class="rounded-full h-6 w-6" src="@/assets/images/pp-2.png" alt="pp-2" /> -->
              <p>
                <template v-if="data.shared_with.length > 0">
                  <span v-for="(item, index) in data.shared_with" :key="index">
                    {{ item
                    }}<span v-if="index < data.shared_with.length - 1">, </span>
                  </span>
                </template>
                <template v-else-if="data.is_private === false">
                  Public
                </template>
              </p>

              <!-- <p>
                {{
                  data.shared_with.length > 0
                    ? data.shared_with[0]
                    : data.is_private == false
                    ? "Public"
                    : ""
                }}
              </p> -->
            </li>
            <li
              class="w-5/12 md:w-5/12 flex flex-col md:flex-row items-center lg:justify-between"
            >
              <span>{{ getDateExp(data.expiration_date) }}</span>
              <div
                v-if="data.qrcode"
                @click="
                  barcodeModals = true;
                  link = data.qrcode;
                "
              >
                <img
                  class="my-2 lg:my-0 lg:mx-1 h-6 w-6 cursor-pointer"
                  src="@/assets/images/barcode-sm.png"
                  alt="shareicon-sm"
                />
              </div>

              <div
                v-else-if="data.url"
                @click="copyUrl(data.url)"
                ref="tooltipButton"
              >
                <img
                  class="my-2 lg:my-0 lg:mx-1 h-6 w-6 cursor-pointer"
                  src="@/assets/images/shareicon-sm.png"
                  alt="shareicon-sm"
                  ref="tooltipImage"
                />
                <div
                  v-if="showTooltipLink && data.url == link"
                  class="tooltip ml-2"
                >
                  Link copied
                </div>
              </div>
              <div v-else>
                <p class="my-2 lg:my-0 lg:mx-1 h-6 w-6"></p>
              </div>

              <RadioOnoff
                :checked="data.is_enable"
                :val="data"
                @inputVal="inputVal"
              />
            </li>
          </ul>

          <ModalsConfirm :show="barcodeModals && data.qrcode == link">
            <div slot="actionQuestion" class="relative">
              <div
                class="absolute -top-5 -right-5 text-2xl font-proximaSemibold cursor-pointer"
                @click="barcodeModals = false"
              >
                ✖
              </div>
            </div>
            <div
              slot="actionText"
              class="w-full mt-5 flex flex-col items-center justify-center"
            >
              <div v-if="data.qrcode" class="rounded-xl bg-lightGrey20 p-4">
                <qr-code
                  class="py-3 text-white text-center text-sm font-proximaNormal cursor-pointer mx-auto w-6/12 md:w-6/12 text-primary px-4 mt-4"
                  :text="data.qrcode"
                ></qr-code>
                <!--  <img
            class="mx-auto"
            src="@/assets/images/barcode-lg.png"
            alt="barcode-lg"
          /> -->
              </div>
              <h5 class="mt-4 font-proximaMedium text-2xl">Scan QR Code</h5>
            </div>
            <div slot="leftBtn"></div>
            <div slot="rightBtn"></div>
          </ModalsConfirm>
        </div>
      </div>
      <!-- ---------------end--------------- -->
    </div>
    <div v-else class="mt-10 text-center font-proximaMedium">
      No Data. <br />
      Go back to Pet Records and select the pet.
    </div>
    <div
      class="mt-10 w-full flex justify-center items-center lg:justify-start lg:items-start"
      v-if="pet"
    >
      <AddIcon class="w-16 h-16 cursor-pointer" @click.native="routeTo" />
    </div>
    <div v-else></div>
    <!-- old method -->
    <!-- "showAddSetting = !showAddSetting"
    <SharePetRecordModals
      :show="showAddSetting"
      @close="showAddSetting = false"
      :pet="pet"
      :settingInitAdd="sharingRulesPreferences"
    /> -->
  </div>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import AddIcon from "../icons/AddIcon.vue";
import RadioOnoff from "../forms/RadioOnoff.vue";
import DiaryIcon from "../icons/DiaryIcon.vue";
import TreatmentIcon from "../icons/TreatmentIcon.vue";
import TrainingIcon from "../icons/TrainingIcon.vue";
import PhotoGalleryIcon from "../icons/PhotoGalleryIcon.vue";
import FavouriteServicesIcon from "../icons/FavouriteServicesIcon.vue";
import CareroutineIcon from "../icons/CareroutineIcon.vue";
import BloodDonorStatusIcon from "../icons/BloodDonorStatusIcon.vue";
import BreederStatusIcon from "../icons/BreederStatusIcon.vue";
import MedicationIcon from "../icons/MedicationIcon.vue";
import SharePetRecordModals from "./SharePetRecordModals.vue";
import { nameOfPet } from "../../methods/petMethods";
import { mapActions, mapGetters } from "vuex";
import {
  enableSharingCareBlog,
  stopSharingCareBlog,
} from "../../services/sharingService";
import moment from "moment";
export default {
  components: {
    HeaderGlobal,
    AddIcon,
    RadioOnoff,
    DiaryIcon,
    TreatmentIcon,
    TrainingIcon,
    PhotoGalleryIcon,
    CareroutineIcon,
    MedicationIcon,
    SharePetRecordModals,
    FavouriteServicesIcon,
    BloodDonorStatusIcon,
    BreederStatusIcon,
    ModalsConfirm,
  },
  data() {
    return {
      pet: {},
      showAddSetting: false,
      titlemenu1: this.$t("account_dropdown_menu_pet_records"),
      titlemenu2: "",
      titlemenu3: this.$t("pet_dropdown_menu_sharing_setting"),
      contactsList: [],
      showTooltipLink: false,
      link: null,
      barcodeModals: false,
    };
  },
  computed: {
    ...mapGetters({
      sharingRules: "sharing/getSharingRules",
      sharingRulesPreferences: "sharing/getSharingRulesPreferences",
    }),
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
    this.getuserSharingPreferences(localStorage.getItem("email"));
  },
  mounted() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    if (this.pet) {
      this.titlemenu2 =
        nameOfPet(this.pet.species) + " Record: " + this.pet.callName;

      this.getSharingRules(this.pet.petID);
    }
  },
  methods: {
    ...mapActions({
      getSharingRules: "sharing/getSharingRules",
      updateSharingRule: "sharing/updateSharingRule",
      getuserSharingPreferences: "sharing/getuserSharingPreferences",
    }),
    async copyUrl(data) {
      try {
        await navigator.clipboard.writeText(data);
        console.log(data);
        this.showTooltipLink = true;
        this.link = data;

        // Hide the tooltip after a short delay (adjust as needed)
        setTimeout(() => {
          this.showTooltipLink = false;
        }, 2000);
      } catch (e) {}
    },
    getcontactsList(cont) {
      this.contactsList = cont;
    },
    getDateExp: function (date) {
      return date ? date.substring(0, 10) : "No expiry";
      //moment(date, "DD/MM/YYYY").format("DD/MM/YYYY")
      //console.log(typeof date);
      //return date ? date : "no expiry";
    },
    orderedList(sharingRules) {
      // Sort the list based on the createdAt property
      return sharingRules.slice().sort((a, b) => {
        // Assuming createdAt is a string in 'YYYY-MM-DDTHH:mm:ss' format
        return new Date(b.date_created) - new Date(a.date_created);
      });
    },
    getIconShare(data) {
      if (data.qrcode) {
        return "@/assets/images/barcode-sm.png";
      } else if (data.url) {
        return "@/assets/images/shareicon-sm.png";
      } else {
        return "";
      }
    },
    whichIcon(so) {
      if (so == 1) {
        return "PhotoGalleryIcon";
      } else if (so == 2) {
        return "CareroutineIcon";
      } else if (so == 3) {
        return "TreatmentIcon";
      } else if (so == 4) {
        return "DiaryIcon";
      } else if (so == 5) {
        return "BreederStatusIcon";
      } else if (so == 6) {
        return "BloodDonorStatusIcon";
      } else if (so == 7) {
        return "FavouriteServicesIcon";
      } else if (so == 8) {
        return "MedicationIcon";
      } /* else {
        return "DiaryIcon";
      } */
    },
    async inputVal(val, checked) {
      val["is_enable"] = checked;
      this.updateSharingRule(val);
    },
    routeTo() {
      {
        this.$router.push({
          name: "SharingSettingNew",
        });
      }
    },
  },
};
</script>

<style>
.tooltip {
  position: absolute;
  background-color: #354e57;
  color: #fff;
  padding: 1px;
  border-radius: 4px;
  /*  z-index: 1000; */
  /* You can adjust the positioning styles as needed */
  /*  top: 50px;
  left: 50%; */
  /* transform: translateX(-50%); */
}
</style>
